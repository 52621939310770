import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { throwError, Observable } from 'rxjs';
import { modelLogin, modelComp, TokenParams } from '../../components/auth/login/modelLogin';
import { catchError } from 'rxjs/operators';
import { Events } from 'src/app/shared/service/events.service';
import { DatePipe } from '@angular/common';

// import { ToastController } from '@angular/common';
// import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
    providedIn: 'root'
})
export class settingServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    userprofile: any;

    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }
    getCountryList() {
        return this.http.get(this.baseUrl + 'api/Public/GetCountries');
    }
    getCurrencyList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCurrencies?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getCompanyCurrency() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyCurrencies?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getUserIndex(searchtxt, istech, tracking, roles) {
        return this.http.get(this.baseUrl + 'api/Users/Get_UserIndex?currencyid=' + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&istech=' + istech + '&tracking=' + tracking + '&roles=' + roles);
    }
    deleteUserById(id) {
        return this.http.delete(this.baseUrl + 'api/Users/Delete_User?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTradeList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTraderType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=');
    }
    getCultures() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCultures?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=');
    }
    getBinLocation() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBinLocations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTipSoftwareList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTPIS?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBuisnessTypeList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBusinessTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSchedulerViewsList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSchedulerViews?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSchedulerViewsThemesList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSchedulerEventThemeBy?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getUTCTimeZoneList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetUTCTimezone?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postSettings(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyProfile', data);
    }
    getCompanyProfile(companyid) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyProfile?companyid=' + companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getEmailTemplate(searchtxt, type, code) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_EmailTemplateIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&code=' + code)
    }
    getEmailTemplateById(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_EmailTemplateByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postEmailTemplate(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Settings/Postt_EmailTemplate', data)
    }
    deleteEmailTemplate(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_EmailTemplate?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Ebadmin email templates

    getAdminEmailTemplate(searchtxt, type, code) {
        return this.http.get(this.baseUrl + 'api/EbSettings/Get_EmailTemplateIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&code=' + code)
    }
    getAdminEmailTemplateById(id) {
        return this.http.get(this.baseUrl + 'api/EbSettings/Gett_EmailTemplateByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postAdminEmailTemplate(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/EbSettings/Postt_EmailTemplate', data)
    }

    getEmaillog(searchtxt, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/EmailLog/Get_MailIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fromdate=' + fromdate + '&todate=' + todate)
    }

    getMobileApps() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetMobileApps?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCompanyUserRoles() {
        return this.http.get(this.baseUrl + 'api/Comman/Get_CompanyUserRoles?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getUserById(id) {
        return this.http.get(this.baseUrl + 'api/Users/Get_UserByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getUserDevices(id) {
        return this.http.get(this.baseUrl + 'api/Users/Get_UserDevices?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteUserDevice(id) {
        return this.http.delete(this.baseUrl + 'api/Users/Delete_UserDevice?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postUsers(data) {
        return this.http.post(this.baseUrl + 'api/Users/Postt_User', data);
    }
    postMapUser(data) {
        return this.http.post(this.baseUrl + 'api/Users/Postt_MapUser', data);
    }
    getCheckUserAvailability(email) {
        return this.http.get(this.baseUrl + 'api/Comman/Check_UserAvailability?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&email=' + email)
    }

    postSendOTP2ExistingUser(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Users/Post_SendOTP2ExistingUser', val);
    }

    postContact(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Customers/Postt_CustomerContact', val);
    }

    postFinYear(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyFinYear', val);
    }
    deleteCompanyContacts(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_Companycontact?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postBank(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyBank', val);
    }
    getCompShiftById(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyShift?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Company Shift==
    getCompShiftList(description) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyShifts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }
    deleteCompanyBank(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyBank?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postShift(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyShift', val);
    }
    deleteCompanyShift(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyShift?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // ===
    postCurrency(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyCurrency', val);
    }
    deleteCompanyCurrency(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyCurrency?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Company menu======
    getCompanyMenuIndex(linktext, ang_root, active) {
        return this.http.get(this.baseUrl + 'api/Master/Get_CompanyMenuIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&linktext=' + linktext + '&ang_root=' + ang_root + '&active=' + active)
    }
    postCompanyMenu(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Master/Postt_CompanyMenu', val);
    }
    postCompanyMenuAction(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Master/Postt_CompanyMenuAction', val);
    }
    getCompanyMenuById(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_CompanyMenuByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCompanyParentMenus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyParentMenuId?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getParentMenus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetParentMenuId?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postExporter(data) {
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyShippingVendor', data)
    }
    getCountries(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyCountries?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getDataType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDataTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBankList(searchtxt) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyBanks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt);
    }
    getCompBankById(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyBanks?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // Financial year
    getFinancialYearList(startdate, enddate) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyFinYears?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&startdate=' + startdate + '&enddate=' + enddate);
    }

    // Tax Configuration
    getTaxConfigList(name, type) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyTaxes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&type=' + type);
    }
    deleteTaxConfigById(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyTax?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // ===========

    // CompanyCurrencies
    getCurrencieList(name) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyCurrencies?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name);
    }
    getCurrencieById(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyCurrencies?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // ===========

    // CompanyCountries
    getCompCountriesList() {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyCountries?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // ===========

    // ShippingVendors
    getTraderList() {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyShippingVendors?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTraderById(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyShippingVendorByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteTraderById(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyShippingVendor?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // ===========

    // CompanyEmailGroups
    getEmailGrpList(name) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyEmailGroups?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name);
    }
    getEmailGrpById(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyEmailLists?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteEmailGrpById(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyEmailLists?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // ===========

    // Payment Method
    getPaymentMethodList(name) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyMOPs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name);
    }
    // ===========

    // ReminderConfigs
    getReminderConfigsList(type) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyReminderConfigs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type);
    }
    getReminderConfigsById(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyReminderConfig?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // ===========

    getCompPrintersList(name) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyPrinters?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name);
    }
    postPrinters(data) {
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyPrinters', data);
    }
    getCompPrintersById(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyPrinters?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postCompanyEmails(data) {
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyEmailLists', data);
    }
    postCompanyReminder(data) {
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyReminderConfig', data);

    }
    postAssetsCategory(data) {
        return this.http.post(this.baseUrl + 'api/Settings/Post_AssetCategory', data);
    }

    postCompanyTariff(data) {
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyTariff', data);
    }
    getcompTariffList(description, code) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyTariffs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description + '&code=' + code);
    }

    postCountry(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyCountry', val);
    }

    getAssetCatDetails(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_AssetCategories?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    postTaxConfiguration(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompantTax', val);
    }
    getTaxDetailsById(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyTax?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCodes(ser) {
        return this.http.get(this.baseUrl + 'api/Comman/GetTariffList?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + ser)
    }
    getTarrfiDetails(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyTariff?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    postSite(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanySite', val);
    }
    getCompanyLocationById(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanySite?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCompanyLocationList() {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyLocations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postUserBin(data) {
        return this.http.post(this.baseUrl + 'api/Users/Post_Userbins', data);
    }

    getBinlocation() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBinLocations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getUserBinbyId(id) {
        return this.http.get(this.baseUrl + 'api/Users/Get_UserBinLocation?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteBinUser(id) {
        return this.http.delete(this.baseUrl + 'api/Users/Delete_Userbins?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCompanyLocations() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyLocations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postUserSite(data) {
        return this.http.post(this.baseUrl + 'api/Users/Post_UserSite', data)
    }
    getUserSitebyId(id) {
        return this.http.get(this.baseUrl + 'api/Users/Get_UserSite?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Timezone
    postTimezone(data) {
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmployeeTimezone', data)
    }
    getTimezonebyId(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeTimezon?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getClockTimezone() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetClockTimezones?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteTimeZone(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmployeeTimezone?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Company salary component
    getCompanySalaryComponentbyId(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanySalaryComponent?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteCompanySalaryComponentbyId(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanySalaryComponent?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postCompanySalaryComponent(data) {
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanySalaryComponent', data)
    }
    getSalaryComponentList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSalaryComponent?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // HR-EMP-getComapnysalartCom
    getCompanySalaryComponentList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanySalaryComponent?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    //Company Third Party App
    getThirdPartyApps() {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyThirdPartyApps?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getThirdPartyAppById(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyThirdPartyApp?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteThirdPartyAppById(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyThirdPartyApp?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postThirdPartyApp(data) {
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyThirdPartyApp', data)
    }
    deleteCompanyMenuAction(id) {
        return this.http.delete(this.baseUrl + 'api/Menu/Delete_MenuAction?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteCompanyMenu(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_CompanyMenu?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCompanyContactById(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ContactByID/' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteCompContact(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_Contact/' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postCompanyContact(data) {
        return this.http.post(this.baseUrl + 'api/Master/Postt_Contact', data)
    }
    //post reminder config
    getReminderConfigList(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyReminderConfig?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteReminderConfig(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_CompanyMenu?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    deleteCountry(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyCountry?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Import service
    getImportDataOptions() {
        return this.http.get(this.baseUrl + 'api/Comman/GetImportDataOptions?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    importCustomer(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Customers', data);
    }

    importSupplier(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Suppliers', data);
    }

    importProduct(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Products', data);
    }
    importAssets(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Assets', data);
    }
    importBins(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Bins', data);
    }
    importEmployees(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Employees', data);
    }
    importInvoices(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Invoices', data);
    }
    importLeads(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Leads', data);
    }
    importCategories(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_ProdCats', data);
    }
    importTasks(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Tasks', data);
    }
    importTickets(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Tickets', data);
    }
    importProjects(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Projects', data);
    }
    importServiceItems(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_ServiceItems', data);
    }
    importServiceChecklists(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_ServiceCheckLists', data);
    }
    importContracts(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Contracts', data);
    }
    importSorder(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_SalesOrders', data);
    }
    importPorder(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_PurchaseOrders', data);
    }
    importPinvoices(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_PurchaseInvoices', data);
    }
    importQuotes(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Quote', data);
    }
    importExpenses(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Expenses', data);
    }
    importPayments(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_Payments', data);
    }
    importEmployeeShifts(data) {
        return this.http.post(this.baseUrl + 'api/Import/Import_EmployeeShifts', data);
    }
    // Company site
    getCompanySites(name, active) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_Sites?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&active=' + active);
    }
    postCompanySites(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_Site', data);
    }
    deleteCompanySites(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_Site?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCompanySiteByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_SiteByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // Company Contact
    getCompanyContact(name, active) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_Contacts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&active=' + active);
    }

    deleteCompanyContact(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_Contact?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }



    // getCompanycontactByID(id) {
    //     return this.http.get(this.baseUrl + 'api/Master/Gett_ContactByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    // }
    getCompanyDevice(name, ilc, type, active) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_Devices?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&ilc=' + ilc + '&type=' + type + '&active=' + active);
    }
    postCompanyDevice(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_Device', data);
    }
    deleteCompanyDevice(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_Device?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCompanyDeviceByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DeviceByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDeviceDetailsList(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DeviceDetails?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteDeviceDetails(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DeviceDetails/' + id + '?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDeviceDocsList(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DeviceFiles?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteDeviceDocs(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DeviceFiles?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postDeviceDetails(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_DeviceDetails', data);
    }
    postCompanyContacts(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyContacts', data);
    }
    deleteReminderConfigItem(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyReminderConfig?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deletePrinter(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyPrinters?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //SQL jobs
    getSQLJobs(name, recurring_pattern, active) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanySqlJobs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&recurring_pattern=' + recurring_pattern + '&active=' + active);
    }
    getSQLJobByID(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanySQlJob?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postSQLJob(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanySQlJob', data);
    }
    deleteSQLJobs(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanySQlJob?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // Company awards

    getAwardList(name, active) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyAwards?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&active=' + active);
    }
    getawardByID(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyAward?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postAwards(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyAward', data);
    }
    deleteAwards(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyAward?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //income category
    getIncomeCategoryList(description) {
        return this.http.get(this.baseUrl + 'api/Income/Get_IncomeCategoryIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }
    getIncomeCategoryByID(id) {
        return this.http.get(this.baseUrl + 'api/Income/Gett_IncomeCategoryByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postIncomeCategory(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Income/Postt_IncomeCategory', data);
    }
    deleteIncomeCategory(id) {
        return this.http.delete(this.baseUrl + 'api/Income/Delete_IncomeCategory?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //emailer service
    getEmailerIndex(searchtxt, fromdate, todate, status) {
        return this.http.get(this.baseUrl + 'api/Emailer/Get_EmailerIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getEmailerByID(id) {
        return this.http.get(this.baseUrl + 'api/Emailer/Get_EmailerByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSubmitEmailer(id) {
        return this.http.get(this.baseUrl + 'api/Emailer/Submit_Emailer?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postEmailer(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Emailer/Post_Emailer', data);
    }
    deleteEmailer(id) {
        return this.http.delete(this.baseUrl + 'api/Emailer/Delete_Emailer?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //CompanyTransactionConfig service
    getCompanyTransactionConfigIndex(description, active) {
        return this.http.get(this.baseUrl + 'api/Master/Get_CompanyTransactionConfigIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description + '&active=' + active);
    }
    getCompanyTransactionConfigByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_CompanyTransactionConfigByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postCompanyTransactionConfig(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_CompanyTransactionConfig', data);
    }
    deleteCompanyTransactionConfig(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_CompanyTransactionConfig?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //CompanyUserRoleIndex service
    getCompanyUserRoleIndex(description, active) {
        return this.http.get(this.baseUrl + 'api/Master/Get_CompanyUserRoleIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description + '&active=' + active);
    }
    getCompanyUserRoleByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_CompanyUserRoleByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postCompanyUserRole(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_CompanyUserRole', data);
    }
    deleteCompanyUserRole(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_CompanyUserRole?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //tds types
    getTDSTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Expenses/Get_TDSTypeIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }
    getTDSTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Expenses/Gett_TDSTypeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postTDSType(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Expenses/Postt_TDSType', data);
    }
    deleteTDSType(id) {
        return this.http.delete(this.baseUrl + 'api/Expenses/Delete_TDSType?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}
